import "./main.scss";

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import Home from "../components/views/Home.vue";
import Dashboard from "../components/views/Dashboard.vue";
import Reporting from "../components/views/Reporting.vue";
import Signatures from "../components/views/Signatures.vue";
import UserActions from "../components/views/UserActions.vue";
import EmbeddedDashboard from "../components/views/EmbeddedDashboard.vue"
import ROICalculator from "../components/views/ROICalculator.vue"
import NotificationUsers from "../components/views/NotificationUsers.vue";

import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-047817 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Optimum Fleet Health Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( OFH Portal )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( OFH Portal )_need_to_be_licensed___( OFH Portal )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 November 2024 )____[v2]_MTczMTYyODgwMDAwMA==89317b47c260912cd6136678c42b9dd0")

const pinia = createPinia()

if (document.querySelector('#home')) {
  const home = createApp(Home);
  home.use(pinia);
  home.mount('#home');
}

if (document.querySelector('#dashboard')) {
  const dashboard = createApp(Dashboard);
  dashboard.use(pinia);
  dashboard.mount('#dashboard');
}

if (document.querySelector('#reporting')) {
  const reporting = createApp(Reporting);
  reporting.use(pinia);
  reporting.mount('#reporting');
}

if (document.querySelector('#signatures')) {
  const signatures = createApp(Signatures);
  signatures.use(pinia);
  signatures.mount('#signatures');
}

if (document.querySelector('#user_actions')) {
  const user_actions = createApp(UserActions);
  user_actions.use(pinia);
  user_actions.mount('#user_actions');
}

if (document.querySelector('#embedded_dashboard')) {
  const embedded_dashboard = createApp(EmbeddedDashboard);
  embedded_dashboard.use(pinia);
  embedded_dashboard.mount('#embedded_dashboard');
}

if (document.querySelector('#roi_calculator')) {
  const roi_calculator = createApp(ROICalculator);
  roi_calculator.use(pinia);
  roi_calculator.mount('#roi_calculator');
}

if (document.querySelector('#notification_users')) {
  const notification_users = createApp(NotificationUsers);
  notification_users.use(pinia);
  notification_users.mount('#notification_users');
}
